import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, CardContainer, LightButton } from "best-common-react";
import PropTypes from "prop-types";
import ReactToolTip from "react-tooltip";
import styled, { ThemeContext, withTheme } from "styled-components";
import Theme, { FormSelectStyles } from "../../Theme";
import { CompConstants } from "../../constants/CompConstants";
import { LookupConstants } from "../../constants/LookupConstants";
import PiaWidget from "../widgets/PiaWidget";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import SearchBar from "../widgets/SearchBar";
import TitleCard from "../widgets/TitleCard";

const ImageColumn = styled.div`
  flex: 0 0 90px;
`;

const SearchColumn = styled.div`
  flex: 1;
`;

const TableContainer = styled.div`
  height: 135px;
`;

class QuickOneOnOne extends React.Component {
  getYearOptions() {
    return [
      { value: 2019, label: "2019" },
      { value: 2018, label: "2018" },
      { value: 2017, label: "2017" },
      { value: 2016, label: "2016" }
    ];
  }
  getStatTable(pl) {
    return (
      <React.Fragment>
        <table className="h-50 text-center layout-fixed">
          <tbody>
            <tr>
              <th>MLS</th>
              <th>{pl.year ? pl.year + " Salary" : "Salary"}</th>
              <th>{pl.yearPlusOne ? pl.yearPlusOne + " Salary" : "Salary"}</th>
            </tr>
            <tr>
              <td>{pl.currentMLS ? pl.currentMLS : "-"}</td>
              <td>
                {pl.baseSalaryDisplay
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0
                    }).format(pl.baseSalaryDisplay)
                  : "-"}
              </td>
              <td>
                {pl.baseSalaryNextYearDisplay
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0
                    }).format(pl.baseSalaryNextYearDisplay)
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        {pl.startingPitcher ? (
          <table className="h-50 text-center">
            <tbody>
              <tr>
                <th>GS</th>
                <th>W-L</th>
                <th>QS</th>
                <th>IP</th>
                <th>ERA</th>
                <th>SO</th>
                <th>SO/BB</th>
                <th>HR/9</th>
                <th>WHIP</th>
                <th>OOPS</th>
              </tr>
              <tr>
                <td>{pl.games ? pl.games : "-"}</td>
                <td>{pl.winsLosses ? pl.winsLosses : "-"}</td>
                <td>{pl.qualityStarts ? pl.qualityStarts : "-"}</td>
                <td>{pl.inningsPitched ? pl.inningsPitched : "-"}</td>
                <td>{pl.earnedRunAverage ? pl.earnedRunAverage : "-"}</td>
                <td>{pl.strikeOuts ? pl.strikeOuts : "-"}</td>
                <td>{pl.strikeOutBB ? pl.strikeOutBB : "-"}</td>
                <td>{pl.homeRunsPerInning ? pl.homeRunsPerInning : "-"}</td>
                <td>{pl.walksPlusHitsPerIP ? pl.walksPlusHitsPerIP : "-"}</td>
                <td>{pl.opponentOPS ? pl.opponentOPS : "-"}</td>
              </tr>
            </tbody>
          </table>
        ) : pl.reliefPitcher ? (
          <table className="h-50 text-center">
            <tbody>
              <tr>
                <th>G</th>
                <th>IP</th>
                <th>W-L</th>
                <th>SV</th>
                <th>SV%</th>
                <th>HLD</th>
                <th>SO/BB</th>
                <th>ERA</th>
                <th>WHIP</th>
                <th>OOPS</th>
              </tr>
              <tr>
                <td>{pl.games ? pl.games : "-"}</td>
                <td>{pl.inningsPitched ? pl.inningsPitched : "-"}</td>
                <td>{pl.winsLosses ? pl.winsLosses : "-"}</td>
                <td>{pl.saves ? pl.saves : "-"}</td>
                <td>{pl.savePercentage ? pl.savePercentage : "-"}</td>
                <td>{pl.holds ? pl.holds : "-"}</td>
                <td>{pl.strikeOutBB ? pl.strikeOutBB : "-"}</td>
                <td>{pl.earnedRunAverage ? pl.earnedRunAverage : "-"}</td>
                <td>{pl.walksPlusHitsPerIP ? pl.walksPlusHitsPerIP : "-"}</td>
                <td>{pl.opponentOPS ? pl.opponentOPS : "-"}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="h-50 text-center">
            <tbody>
              <tr>
                <th>G</th>
                <th>PA</th>
                <th>HR</th>
                <th>RBI</th>
                <th>XBH</th>
                <th>SB</th>
                <th>AVG</th>
                <th>OBP</th>
                <th>SLG</th>
                <th>OPS</th>
              </tr>
              <tr>
                <td>{pl.games ? pl.games : "-"}</td>
                <td>{pl.plateAppearances ? pl.plateAppearances : "-"}</td>
                <td>{pl.homeRuns ? pl.homeRuns : "-"}</td>
                <td>{pl.runsBattedIn ? pl.runsBattedIn : "-"}</td>
                <td>{pl.extraBaseHits ? pl.extraBaseHits : "-"}</td>
                <td>{pl.stolenBases ? pl.stolenBases : "-"}</td>
                <td>{pl.battingAvg ? pl.battingAvg : "-"}</td>
                <td>{pl.onBasePercentage ? pl.onBasePercentage : "-"}</td>
                <td>{pl.sluggingPercentage ? pl.sluggingPercentage : "-"}</td>
                <td>{pl.onBasePlusSluggingPct ? pl.onBasePlusSluggingPct : "-"}</td>
              </tr>
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
  getToolTipHtml(homePlateStore, lookupStore) {
    let salaryTypes = this.getSalaryTypeOptions();
    let timeframes = this.getTimeFramesForDropdown(lookupStore);
    let prorateTypes = this.getProrateOptions();
    return (
      <ThemeContext.Provider value={Theme}>
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">Report Settings</span>
          </CardContainer.Header>
          <CardContainer.Body>
            <div
              className="container-fluid px-0"
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <div className="row m-0">
                <div className="col-12 px-0 pb-2">
                  <BestSelect
                    name="rpStatLists"
                    value={lookupStore.getDropdownItem(
                      homePlateStore.statLists,
                      homePlateStore.quick1On1.statListId,
                      "statlistId",
                      "statlistname"
                    )}
                    onChange={option => {
                      homePlateStore.quick1On1.statListId = option.statlistId;
                    }}
                    options={homePlateStore.statLists}
                    getOptionValue={option => option.statlistId}
                    getOptionLabel={option => option.statlistname}
                    placeholder="Stat List"
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
                <div className="col-12 px-0 pb-2">
                  <BestSelect
                    name="rpSalaryTypes"
                    value={lookupStore.getDropdownItem(salaryTypes, homePlateStore.quick1On1.salaryTypeId)}
                    onChange={option => {
                      homePlateStore.quick1On1.salaryTypeId = option.value;
                    }}
                    options={salaryTypes}
                    placeholder="Salary Type"
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
                <div className="col-12 px-0 pb-2">
                  <BestSelect
                    name="rpTimeFrames"
                    value={lookupStore.getDropdownItem(
                      timeframes,
                      homePlateStore.quick1On1.timeFrameId,
                      "timeframeId",
                      "timeframeName"
                    )}
                    onChange={option => {
                      homePlateStore.quick1On1.timeFrameId = option.timeframeId;
                    }}
                    options={timeframes}
                    getOptionValue={option => option.timeframeId}
                    getOptionLabel={option => option.timeframeName}
                    placeholder="Time Frame"
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
                <div className="col-12 px-0">
                  <BestSelect
                    name="rpProrateTypes"
                    value={lookupStore.getDropdownItem(prorateTypes, homePlateStore.quick1On1.prorateType)}
                    onChange={option => {
                      homePlateStore.quick1On1.prorateType = option.value;
                    }}
                    options={prorateTypes}
                    placeholder="Prorate Type"
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="container-fluid px-0">
              <div className="header-item-row m-0 px-0">
                <LightButton
                  className="flex-fill"
                  onClick={() => {
                    homePlateStore.runQuickOneOnOne();
                    ReactToolTip.hide();
                  }}
                >
                  Quick Report
                </LightButton>
                <LightButton
                  className="flex-fill"
                  onClick={() => {
                    homePlateStore.createOneOnOneComp();
                    ReactToolTip.hide();
                  }}
                >
                  Comparison
                </LightButton>
              </div>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </ThemeContext.Provider>
    );
  }
  getSalaryTypeOptions() {
    return [
      { value: 1, label: "Show LRD No Bonus" },
      { value: 2, label: "Show LRD Salary" },
      { value: 3, label: "Show LRD Salary Raise" },
      { value: 0, label: "Show AAV" }
    ];
  }
  getTimeFramesForDropdown(lookupStore) {
    let timeframes = lookupStore.getLookup(LookupConstants.USER_TIMEFRAMES);
    if (!timeframes) {
      return [];
    }
    return timeframes.filter(
      tf =>
        tf.timeframereporttypeLk &&
        tf.timeframereporttypeLk.timeframereporttypeLk === CompConstants.TIMEFRAME_REPORT_TYPES.ONE_ON_ONE
    );
  }
  getProrateOptions() {
    return [
      { value: "prorate", label: "Prorate current season stats" },
      { value: "actuals", label: "Use current season actuals" },
      { value: "last", label: "Use last season statistics" },
      { value: "per600", label: "Normalize over 600 PAs" },
      { value: "per162", label: "Use career 162 game average" }
    ];
  }
  render() {
    let { commonStore, homePlateStore, lookupStore, playerStore, piaApi } = this.props.rootStore;
    let years = commonStore.getListOfYears(1988, 0);
    let servicesURL = process.env.REACT_APP_SERVICES_URL;
    return (
      <PiaWidget className="col-12">
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">Quick One-on-One Report</span>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="q1on1-grid">
                <div className="q1on1-player">
                  <div className="row mx-0 mb-2">
                    <div className="col-12 p-0">
                      <TitleCard>
                        <span>Player 1</span>
                      </TitleCard>
                    </div>
                  </div>

                  <div className="row m-0">
                    <ImageColumn className="p-0">
                      <div className="p-0 mb-2">
                        <BestSelect
                          name="p1Year"
                          value={lookupStore.getDropdownItem(years, homePlateStore.quick1On1.year1)}
                          onChange={option => {
                            homePlateStore.quick1On1.year1 = option.value;
                            if (homePlateStore.quick1On1.player1.playerId) {
                              piaApi
                                .getHomePlatePlayer(
                                  homePlateStore.quick1On1.player1.playerId,
                                  homePlateStore.quick1On1.year1
                                )
                                .then(response => {
                                  if (response) {
                                    homePlateStore.setPlayer1(response);
                                  } else {
                                    homePlateStore.setPlayer1({ playerId: homePlateStore.quick1On1.player1.playerId });
                                  }
                                });
                            }
                          }}
                          options={years}
                          styles={FormSelectStyles}
                        />
                      </div>
                      <div className="col-12 p-0">
                        {homePlateStore.quick1On1.player1.imageURL ? (
                          <img
                            className="player-image col-12 p-0"
                            src={servicesURL + homePlateStore.quick1On1.player1.imageURL}
                          />
                        ) : (
                          <div className="player-placeholder col-12 p-0" />
                        )}
                      </div>
                    </ImageColumn>
                    <SearchColumn>
                      <div className="row m-0">
                        <div className="col-7 mb-2">
                          <SearchBar
                            options={lookupStore.autocompletePlayers}
                            renderSuggestion={suggestion => {
                              return <PlayerAutocompleteRow player={suggestion} />;
                            }}
                            getSuggestionValue={player => {
                              return playerStore.getAutocompleteText(player);
                            }}
                            getSuggestions={playerStore.filterBySearchTerm}
                            placeholder="type to search players"
                            onClearFn={event => {
                              homePlateStore.quick1On1.player1.playerId = null;
                            }}
                            onSuggestionSelected={(event, { suggestion }) => {
                              piaApi
                                .getHomePlatePlayer(suggestion.playerId, homePlateStore.quick1On1.year1)
                                .then(response => {
                                  if (response) {
                                    homePlateStore.setPlayer1(response);
                                  } else {
                                    homePlateStore.setPlayer1({ playerId: suggestion.playerId });
                                  }
                                });
                            }}
                          />
                        </div>

                        <div className="d-flex col-5 pr-0 justify-content-end">
                          {homePlateStore.quick1On1.player1.playerId ? (
                            <a
                              href="/#"
                              onClick={event => {
                                event.preventDefault();
                                playerStore.openPlayerProfile(homePlateStore.quick1On1.player1.playerId);
                              }}
                            >
                              View Profile
                            </a>
                          ) : (
                            <span>View Profile</span>
                          )}
                        </div>
                      </div>

                      <TableContainer className="row m-0">
                        <div className="col-12 pr-0">{this.getStatTable(homePlateStore.quick1On1.player1)}</div>
                      </TableContainer>
                    </SearchColumn>
                  </div>
                </div>

                <div className="q1on1-player">
                  <div className="row mx-0 mb-2">
                    <div className="col-12 p-0">
                      <TitleCard>
                        <span>Player 2</span>
                      </TitleCard>
                    </div>
                  </div>
                  <div className="row m-0">
                    <ImageColumn className="p-0">
                      <div className="p-0 mb-2">
                        <BestSelect
                          name="p2Year"
                          value={lookupStore.getDropdownItem(years, homePlateStore.quick1On1.year2)}
                          onChange={option => {
                            homePlateStore.quick1On1.year2 = option.value;
                            if (homePlateStore.quick1On1.player2.playerId) {
                              piaApi
                                .getHomePlatePlayer(
                                  homePlateStore.quick1On1.player2.playerId,
                                  homePlateStore.quick1On1.year2
                                )
                                .then(response => {
                                  if (response) {
                                    homePlateStore.setPlayer2(response);
                                  } else {
                                    homePlateStore.setPlayer2({ playerId: homePlateStore.quick1On1.player2.playerId });
                                  }
                                });
                            }
                          }}
                          options={years}
                          styles={FormSelectStyles}
                        />
                      </div>
                      <div className="col-12 p-0">
                        {homePlateStore.quick1On1.player2.imageURL ? (
                          <img
                            className="player-image col-12 p-0"
                            src={servicesURL + homePlateStore.quick1On1.player2.imageURL}
                          />
                        ) : (
                          <div className="player-placeholder col-12 p-0" />
                        )}
                      </div>
                    </ImageColumn>
                    <SearchColumn>
                      <div className="row m-0">
                        <div className="col-7 mb-2">
                          <SearchBar
                            options={lookupStore.autocompletePlayers}
                            renderSuggestion={suggestion => {
                              return <PlayerAutocompleteRow player={suggestion} />;
                            }}
                            getSuggestionValue={player => {
                              return playerStore.getAutocompleteText(player);
                            }}
                            getSuggestions={playerStore.filterBySearchTerm}
                            placeholder="type to search players"
                            onClearFn={event => {
                              homePlateStore.quick1On1.player2.playerId = null;
                            }}
                            onSuggestionSelected={(event, { suggestion }) => {
                              piaApi
                                .getHomePlatePlayer(suggestion.playerId, homePlateStore.quick1On1.year2)
                                .then(response => {
                                  if (response) {
                                    homePlateStore.setPlayer2(response);
                                  } else {
                                    homePlateStore.setPlayer2({ playerId: suggestion.playerId });
                                  }
                                });
                            }}
                          />
                        </div>
                        <div className="d-flex col-5 pr-0 justify-content-end">
                          {homePlateStore.quick1On1.player2.playerId ? (
                            <a
                              href="/#"
                              onClick={event => {
                                event.preventDefault();
                                playerStore.openPlayerProfile(homePlateStore.quick1On1.player2.playerId);
                              }}
                            >
                              View Profile
                            </a>
                          ) : (
                            <span>View Profile</span>
                          )}
                        </div>
                      </div>
                      <TableContainer className="row m-0">
                        <div className="col-12 pr-0">{this.getStatTable(homePlateStore.quick1On1.player2)}</div>
                      </TableContainer>
                    </SearchColumn>
                  </div>
                </div>
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              {
                <React.Fragment>
                  <span data-for="report-options-tooltip" data-tip="" data-event="click">
                    <LightButton>One-on-One Report</LightButton>
                  </span>
                  <ReactToolTip
                    id="report-options-tooltip"
                    class="tool-options"
                    effect="solid"
                    place="left"
                    globalEventOff="click"
                    scrollHide={false}
                    clickable={true}
                  >
                    {this.getToolTipHtml(homePlateStore, lookupStore)}
                  </ReactToolTip>
                </React.Fragment>
              }
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
}

QuickOneOnOne.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(QuickOneOnOne)));
