import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";
import { BestSelect, Checkbox, NumberInput, PrimaryButton } from "best-common-react";
import { FormSelectButtonStyles } from "../../Theme";
import { CompConstants } from "../../constants/CompConstants";
import TitleCard from "../widgets/TitleCard";

class AdditionalOptions extends React.Component {
  getPlayerNameFormatOptions() {
    return [
      { value: "RLFM", label: "Last, Roster M." },
      { value: "RLF", label: "Last, Roster" },
      { value: "RFML", label: "Roster M. Last" },
      { value: "RFL", label: "Roster Last" },
      { value: "RLMO", label: "Last, Roster M. (ORG)" },
      { value: "RLO", label: "Last, Roster (ORG)" },
      { value: "RFMO", label: "Roster M. Last (ORG)" },
      { value: "RFO", label: "Roster Last (ORG)" },
      { value: "RLA", label: "Last, Roster M. (ORG - Pos1)" },
      { value: "RLOP", label: "Last, Roster (ORG - Pos1)" },
      { value: "RFA", label: "Roster M. Last (ORG - Pos1)" },
      { value: "RFOP", label: "Roster Last (ORG - Pos1)" },
      { value: "BLFM", label: "Last, Birth M." },
      { value: "BFML", label: "Birth M. Last" },
      { value: "BLMO", label: "Last, Birth M. (ORG)" },
      { value: "BFMO", label: "Birth M. Last (ORG)" },
      { value: "BLA", label: "Last, Birth M. (ORG - Pos1)" },
      { value: "BFA", label: "Birth M. Last (ORG - Pos1)" }
    ];
  }
  getProrateOptions(tab) {
    let options = [
      { value: "prorate", label: "Prorate current season stats" },
      { value: "actuals", label: "Use current season actuals" },
      { value: "last", label: "Use last season statistics" },
      { value: "per162", label: "Use career 162 game average" },
      { value: "per600", label: "Normalize over 600 PAs" }
    ];

    // Add normalize options if not on Performance tab
    if (tab !== CompConstants.TABS.PERFORMANCE) {
      options.push({ value: "perPY-1", label: "Normalize over PY - 1 PAs" });
      options.push({ value: "perPY-2", label: "Normalize over PY - 2 PAs" });
      options.push({ value: "perPY-3", label: "Normalize over PY - 3 PAs" });
    }

    return options;
  }
  getScale2020Options() {
    return [
      { value: 60, label: "Do not scale" },
      { value: 162, label: "Scale 2020 stats over 162 Games" },
      { value: 111, label: "Scale 2020 stats over 111 Games" }
    ];
  }
  getContractYearOptions() {
    return [
      { value: "F", label: "First" },
      { value: "L", label: "Last" }
    ];
  }
  getOneOnOneSalaryOptions() {
    return [
      { value: 1, label: "LRD Salary No Bonus" },
      { value: 2, label: "LRD Salary" },
      { value: 3, label: "LRD Salary Raise" },
      { value: 0, label: "AAV" }
    ];
  }
  getOneOnOnePlayerSortOptions() {
    return [
      { value: "PN", label: "Player Name" },
      { value: "SD", label: "Salary (desc)" },
      { value: "SA", label: "Salary (asc)" }
    ];
  }
  getRankingDisplayOptions() {
    return [
      { value: "STATS", label: "Show Stats" },
      { value: "RANKS", label: "Show Ranks" },
      { value: "RANKS_STATS", label: "Show Rank and Stats" },
      { value: "STATS_PERCENTILES", label: "Show Percentiles and Stats" },
      { value: "RANKS_PERCENTILES", label: "Show Ranks and Percentiles" },
      { value: "RANKS_STATS_PERCENTILES", label: "Show Ranks, Percentiles, and Stats" }
    ];
  }
  getSummarySortOptions(addAvgRank = true) {
    let list = [
      { value: "selStat", label: "Selected Stat" },
      { value: "playerName", label: "Player Name" }
    ];

    if (addAvgRank) {
      list.unshift({ value: "avgRank", label: "Average Rank" });
    }

    return list;
  }
  getSummaryGroupOptions() {
    return [
      { value: "SPT-TF-ST", label: "Split - Time Frame - Sort Type" },
      { value: "TF-SPT-ST", label: "Time Frame - Split - Sort Type" },
      { value: "ST-SPT-TF", label: "Sort Type - Split - Time Frame" },
      { value: "ST-TF-SPT", label: "Sort Type - Time Frame - Split" }
    ];
  }
  getPlayerGroupingOptions() {
    return [
      { value: "Totals", label: "Totals" },
      { value: "Season", label: "Season" },
      { value: "Month", label: "Month" },
      { value: "Game", label: "Game" }
    ];
  }
  getContractSalaryOptions() {
    return [
      { value: "LRD", label: "LRD Salary" },
      { value: "Base", label: "Base Salary" }
    ];
  }
  render() {
    let { tab } = this.props;
    let { compStore, lookupStore, statStore } = this.props.rootStore;
    let playerNameFormats = this.getPlayerNameFormatOptions();
    let prorates = this.getProrateOptions(tab);
    let scales2020 = this.getScale2020Options();
    let normalizeProrateTypes = _.filter(prorates, option => option.label.indexOf("Normalize") > -1).map(
      prorate => prorate.value
    );
    let contractYears = this.getContractYearOptions();
    let oneOnOneSalaries = this.getOneOnOneSalaryOptions();
    let oneOnOnePlayerSorting = this.getOneOnOnePlayerSortOptions();
    let rankingDisplays = this.getRankingDisplayOptions();
    let rankingSummarySorts = this.getSummarySortOptions();
    let summaryGroups = this.getSummaryGroupOptions();
    let summarySorts = this.getSummarySortOptions(false);
    let playerGroupings = this.getPlayerGroupingOptions();
    let contractSalaries = this.getContractSalaryOptions();
    return (
      <div className={"col-6 " + (tab === CompConstants.TABS.CONTRACT ? "p-0" : "pr-0")}>
        <div className="row mx-0 mb-4">
          <div className="col-12 p-0">
            <TitleCard>
              <span>Additional Options</span>
            </TitleCard>
          </div>
        </div>

        <div className="row m-0">
          <table>
            <tbody>
              <tr>
                <td className="d-flex justify-content-between align-items-center">
                  <span>Player Name Format</span>
                  <div className="col-4 pr-0">
                    <BestSelect
                      name="aoPlayerNameFormat"
                      options={playerNameFormats}
                      onChange={option => {
                        compStore.comp.reportCriteria.selectedPlayerNameFormatCombined = option.value;
                      }}
                      value={lookupStore.getDropdownItem(
                        playerNameFormats,
                        compStore.comp.reportCriteria.selectedPlayerNameFormatCombined
                      )}
                      portal={document.body}
                      styles={FormSelectButtonStyles}
                    />
                  </div>
                </td>
              </tr>
              {tab !== CompConstants.TABS.AWARD &&
              tab !== CompConstants.TABS.INDUSTRY &&
              tab !==
                CompConstants.TABS
                  .CONTRACT /* Don't show prorate/scaling/substition for Award, Industry, or Contract tabs */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Prorating</span>
                      <div className="col-4 pr-0">
                        <BestSelect
                          name="aoProrate"
                          options={prorates}
                          onChange={option => {
                            if (normalizeProrateTypes.indexOf(option.value) > -1) {
                              compStore.comp.prorateType = "per600";
                              compStore.comp.prorateByPAs = option.value;
                            } else {
                              compStore.comp.prorateType = option.value;
                            }
                          }}
                          value={lookupStore.getDropdownItem(
                            prorates,
                            normalizeProrateTypes.indexOf(compStore.comp.prorateType) > -1
                              ? compStore.comp.prorateByPAs
                              : compStore.comp.prorateType
                          )}
                          portal={document.body}
                          styles={FormSelectButtonStyles}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Scale 2020 Stats</span>
                      <div className="col-4 pr-0">
                        <BestSelect
                          name="scale2020Num"
                          options={scales2020}
                          onChange={option => {
                            compStore.comp.scale2020Num = option.value;
                          }}
                          value={lookupStore.getDropdownItem(scales2020, compStore.comp.scale2020Num)}
                          portal={document.body}
                          styles={FormSelectButtonStyles}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Future Year Substitution</span>
                      <PrimaryButton
                        onClick={() => {
                          compStore.futureYearSubModalOpen = true;
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
              {tab === CompConstants.TABS.ONE_ON_ONE ||
              tab === CompConstants.TABS.RANKINGS ||
              tab ===
                CompConstants.TABS
                  .STAT_SUMMARY /* Show highlight options for One-on-One, Rankings, and Stat Summary tabs  */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Highlight Stats &gt; Focus</span>
                      <Checkbox
                        id="aoHighlightFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.highlightFlg = event.target.checked;
                        }}
                        checked={compStore.comp.highlightFlg}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Highlight Focus Player </span>
                      <Checkbox
                        id="aoFocusHighlightFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.focusHighlightFlg = event.target.checked;
                        }}
                        checked={compStore.comp.focusHighlightFlg}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
              {tab === CompConstants.TABS.RANKINGS ||
              tab ===
                CompConstants.TABS.STAT_SUMMARY /* Show avg/median options for Rankings and Stat Summary tabs  */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Show Avg Line</span>
                      <Checkbox
                        id="aoAverageFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.averageFlg = event.target.checked;
                        }}
                        checked={compStore.comp.averageFlg}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Show Med Line</span>
                      <Checkbox
                        id="aoMedianFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.medianFlg = event.target.checked;
                        }}
                        checked={compStore.comp.medianFlg}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
              {tab === CompConstants.TABS.ONE_ON_ONE ||
              tab === CompConstants.TABS.RANKINGS ||
              tab === CompConstants.TABS.STAT_SUMMARY ||
              tab ===
                CompConstants.TABS
                  .INDUSTRY /* Show total salaries option for One-on-One, Rankings, Stat Summary, and Industry tabs  */ ? (
                <tr>
                  <td className="d-flex justify-content-between align-items-center">
                    <span>Total Salaries Over Ranges</span>
                    <Checkbox
                      id="aoAverageFlg"
                      label=""
                      onChange={event => {
                        compStore.comp.careerSalariesFlg = event.target.checked;
                      }}
                      checked={compStore.comp.careerSalariesFlg}
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment />
              )}
              {tab === CompConstants.TABS.ONE_ON_ONE ||
              tab === CompConstants.TABS.RANKINGS ||
              tab === CompConstants.TABS.STAT_SUMMARY ||
              tab === CompConstants.TABS.INDUSTRY ||
              tab ===
                CompConstants.TABS
                  .CONTRACT /* Show contract options for One-on-One, Rankings, Stat Summary, Industry, and Contract tabs  */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Contract to Show in Year</span>
                      <div className="col-4 pr-0">
                        <BestSelect
                          name="aoShowFirstOrLastContract"
                          options={contractYears}
                          onChange={option => {
                            compStore.comp.showFirstOrLastContract = option.value;
                          }}
                          value={lookupStore.getDropdownItem(contractYears, compStore.comp.showFirstOrLastContract)}
                          portal={document.body}
                          styles={FormSelectButtonStyles}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Use PY+1 for contract information</span>
                      <Checkbox
                        id="aoUsePyplusoneFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.usePyplusoneFlg = event.target.checked;
                        }}
                        checked={compStore.comp.usePyplusoneFlg}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}

              {tab === CompConstants.TABS.ONE_ON_ONE /* Show One-on-One options */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Salary to Display</span>
                      <div className="col-4 pr-0">
                        <BestSelect
                          name="aoOneOnOneSalaryType"
                          options={oneOnOneSalaries}
                          onChange={option => {
                            compStore.comp.oneOnOneSalaryType = option.value;
                          }}
                          value={lookupStore.getDropdownItem(oneOnOneSalaries, compStore.comp.oneOnOneSalaryType)}
                          portal={document.body}
                          styles={FormSelectButtonStyles}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Sort Players By</span>
                      <div className="col-4 pr-0">
                        <BestSelect
                          name="aoOneOnOneSort"
                          options={oneOnOnePlayerSorting}
                          onChange={option => {
                            compStore.comp.oneOnOneSort = option.value;
                          }}
                          value={lookupStore.getDropdownItem(oneOnOnePlayerSorting, compStore.comp.oneOnOneSort)}
                          portal={document.body}
                          styles={FormSelectButtonStyles}
                        />
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ) : tab === CompConstants.TABS.RANKINGS /* Show Ranking options */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Display Summary Page</span>
                      <Checkbox
                        id="aoSummaryPageFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.summaryPageFlg = event.target.checked;
                        }}
                        checked={compStore.comp.summaryPageFlg}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Display One Page With All Stats</span>
                      <div className="col-5 p-0 item-row">
                        <div className="item flex-fill">
                          <BestSelect
                            name="aoShowOnRankingReport"
                            options={rankingDisplays}
                            onChange={option => {
                              compStore.comp.showOnRankingReport = option.value;
                            }}
                            value={lookupStore.getDropdownItem(rankingDisplays, compStore.comp.showOnRankingReport)}
                            portal={document.body}
                            styles={FormSelectButtonStyles}
                          />
                        </div>

                        <div className="item">
                          <Checkbox
                            id="aoOnepageallstatsFlg"
                            label=""
                            onChange={event => {
                              compStore.comp.reportCriteria.onepageallstatsFlg = event.target.checked;
                            }}
                            checked={compStore.comp.reportCriteria.onepageallstatsFlg}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Display One Stat Per Page</span>
                      <Checkbox
                        id="aoOnestatperpageFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.reportCriteria.onestatperpageFlg = event.target.checked;
                        }}
                        checked={compStore.comp.reportCriteria.onestatperpageFlg}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Sort Stat Summary By</span>
                      <div className="row m-0 col-6 p-0">
                        <div className="col-6 pl-0">
                          <BestSelect
                            name="aoShowOnRankingReport"
                            options={rankingSummarySorts}
                            onChange={option => {
                              compStore.comp.reportCriteria.selectedForSummary = option.value;
                            }}
                            value={lookupStore.getDropdownItem(
                              rankingSummarySorts,
                              compStore.comp.reportCriteria.selectedForSummary
                            )}
                            portal={document.body}
                            styles={FormSelectButtonStyles}
                          />
                        </div>

                        <div className="col-6 p-0">
                          <BestSelect
                            name="aoRankbystatId"
                            options={statStore.displayStatListDetails}
                            onChange={option => {
                              compStore.comp.rankbystatId = option.statId;
                            }}
                            value={lookupStore.getDropdownItem(
                              statStore.displayStatListDetails,
                              compStore.comp.rankbystatId,
                              "statId",
                              "statisticname"
                            )}
                            getOptionValue={option => option.statId}
                            getOptionLabel={option => option.statisticname}
                            portal={document.body}
                            styles={FormSelectButtonStyles}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ) : tab === CompConstants.TABS.STAT_SUMMARY /* Show Stat Summary options */ ? (
                <React.Fragment>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Display One Page With All Stats</span>
                      <Checkbox
                        id="aoOnepageallstatsFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.reportCriteria.onepageallstatsFlg = event.target.checked;
                        }}
                        checked={compStore.comp.reportCriteria.onepageallstatsFlg}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Display One Stat Per Page</span>
                      <Checkbox
                        id="aoOnestatperpageFlg"
                        label=""
                        onChange={event => {
                          compStore.comp.reportCriteria.onestatperpageFlg = event.target.checked;
                        }}
                        checked={compStore.comp.reportCriteria.onestatperpageFlg}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Group Summary Report By</span>
                      <div className="col-4 pr-0">
                        <BestSelect
                          name="aoGroupSummaryBy"
                          options={summaryGroups}
                          onChange={option => {
                            compStore.comp.groupSummaryBy = option.value;
                          }}
                          value={lookupStore.getDropdownItem(summaryGroups, compStore.comp.groupSummaryBy)}
                          portal={document.body}
                          styles={FormSelectButtonStyles}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between align-items-center">
                      <span>Sort Stat Summary By</span>
                      <div className="row m-0 col-6 p-0">
                        <div className="col-6 pl-0">
                          <BestSelect
                            name="aoShowOnRankingReport"
                            options={summarySorts}
                            onChange={option => {
                              compStore.comp.reportCriteria.statSummarySortType = option.value;
                            }}
                            value={lookupStore.getDropdownItem(
                              summarySorts,
                              compStore.comp.reportCriteria.statSummarySortType
                            )}
                            portal={document.body}
                            styles={FormSelectButtonStyles}
                          />
                        </div>

                        <div className="col-6 p-0">
                          <BestSelect
                            name="aoRankbystatId"
                            options={statStore.displayStatListDetails}
                            onChange={option => {
                              compStore.comp.statSummarySort = option.statId;
                            }}
                            value={lookupStore.getDropdownItem(
                              statStore.displayStatListDetails,
                              compStore.comp.statSummarySort,
                              "statId",
                              "statisticname"
                            )}
                            getOptionValue={option => option.statId}
                            getOptionLabel={option => option.statisticname}
                            portal={document.body}
                            styles={FormSelectButtonStyles}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ) : tab === CompConstants.TABS.PERFORMANCE /* Show Performance options */ ? (
                <tr>
                  <td className="d-flex justify-content-between align-items-center">
                    <span>Number of Players</span>
                    <div className="col-2 pr-0">
                      <NumberInput
                        name="aoNumplayersontopperf"
                        onChange={value => {
                          compStore.comp.numplayersontopperf = value;
                        }}
                        value={compStore.comp.numplayersontopperf}
                      />
                    </div>
                  </td>
                </tr>
              ) : tab === CompConstants.TABS.PLAYER ? (
                /* Show Player options */ <tr>
                  <td className="d-flex justify-content-between align-items-center">
                    <span>Grouping Display</span>
                    <div className="col-4 pr-0">
                      <BestSelect
                        name="aoGroupPlayerStatRepBy"
                        options={playerGroupings}
                        onChange={option => {
                          compStore.comp.reportCriteria.groupPlayerStatRepBy = option.value;
                        }}
                        value={lookupStore.getDropdownItem(
                          playerGroupings,
                          compStore.comp.reportCriteria.groupPlayerStatRepBy
                        )}
                        portal={document.body}
                        styles={FormSelectButtonStyles}
                      />
                    </div>
                  </td>
                </tr>
              ) : tab === CompConstants.TABS.CONTRACT /* Show Contract options */ ? (
                <tr>
                  <td className="d-flex justify-content-between align-items-center">
                    <span>Salary to Display</span>
                    <div className="col-4 pr-0">
                      <BestSelect
                        name="aoLrdOrBase"
                        options={contractSalaries}
                        onChange={option => {
                          compStore.comp.lrdOrBase = option.value;
                        }}
                        value={lookupStore.getDropdownItem(contractSalaries, compStore.comp.lrdOrBase)}
                        portal={document.body}
                        styles={FormSelectButtonStyles}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                <React.Fragment />
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

AdditionalOptions.propTypes = {
  rootStore: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired
};

export default inject("rootStore")(withTheme(observer(AdditionalOptions)));
